// @flow
import * as React from 'react';
import Card from "../../../shared/Card";
import {
  CircularProgress,
  Divider,
  List, ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import {
  ClientViewOutput,
  useClientCalendlyAppointmentsQuery,
  useSetCalendlyAppointmentNoShowMutation
} from "../../../enrollment-types";
import {getUserName} from "../../../shared/tools";
import moment from "moment";
import {LoadingButton} from "@mui/lab";

type Props = {
  client?: ClientViewOutput
};

export function CalendlyAppointments({client}: Props) {
  const [setNoShow, {loading: saveLoading}] = useSetCalendlyAppointmentNoShowMutation({refetchQueries: ['clientCalendlyAppointments']})
  const {loading, data} = useClientCalendlyAppointmentsQuery({
    variables: {clientId: client?.id},
    skip: !client?.id,
    notifyOnNetworkStatusChange: true
  })
  const appointments = data?.clientCalendlyAppointments || [];

  return (
    <Card>
      <Typography variant={'h4'} className={'mb-5'}>Calendly Appointments:</Typography>
      {loading && <CircularProgress size={15} />}
      <List>
        {appointments.map((a, i) => (
          <React.Fragment key={a.url}>
            <ListItem alignItems="flex-start" sx={{px: 0}}>
              <ListItemText
                primary={<Typography sx={{fontSize: 14, fontWeight: 500}}>{getUserName(a.agent)}</Typography>}
                secondary={<Typography sx={{fontSize: 12, color: 'gray'}}>
                  {moment(a.startTime).format('L LT')}
                </Typography>}
              />

              <ListItemSecondaryAction>
                {a.noShow && (
                  <Typography sx={{fontSize: 14, fontWeight: 500}} color={'error'}>Didn't show up</Typography>
                )}
                {!a.noShow && (
                  <LoadingButton loading={saveLoading}
                                 size={'small'}
                                 variant={'contained'}
                                 onClick={() => setNoShow({variables: {id: a.url, noShow: true}})}
                                 color={'secondary'}>No show</LoadingButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
            {i < appointments.length - 1 && <Divider/>}
          </React.Fragment>
        ))}

      </List>
    </Card>
  )
}