import {
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import paths from "./router-paths";
import React, {useContext, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import useIsAdmin from "./shared/hooks/useIsAdmin";
import {keycloak, KeycloakRoles} from "./shared/keycloak";
import {useMissingAppTotalQuery} from "./enrollment-types";
import {config} from "./config/config";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChatIcon from '@mui/icons-material/Chat';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SellIcon from '@mui/icons-material/Sell';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import BlindsClosedIcon from '@mui/icons-material/BlindsClosed';
import EmailIcon from '@mui/icons-material/Email';
import Tooltip from "@mui/material/Tooltip";
import { RippleBadge } from "./shared/RippleBadge";
import {ChatContext} from "./Chat/lib/chat-context";

export default function Navigation() {
  const history = useHistory();
  const [{hasNewMessages}] = useContext(ChatContext)
  const {data: missingAppTotalData} = useMissingAppTotalQuery({variables: {missingAppTagId: config.missingAppsTagId}, pollInterval: 60000});
  const [collapsed, setCollapsed] = useState(false)

  const missingAppTotal = useMemo(() => {
    return missingAppTotalData?.clientViews?.totalElements !== undefined ? `(${missingAppTotalData?.clientViews?.totalElements})` : ''
  }, [missingAppTotalData]);

  const isAdmin = useIsAdmin();

  const isAgentManager = React.useMemo(() => {
    return keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_AGENT_MANAGER)
  }, [keycloak])

  return <List sx={{backgroundColor: 'white', '& .MuiListItemText-primary': {whiteSpace: 'nowrap'}, '& .MuiListItemIcon-root': {minWidth: 20}}}>
    <ListItem disablePadding>
      <ListItemButton sx={{background: '#eee'}} onClick={() => setCollapsed(!collapsed)}>
        {!collapsed && <ChevronLeftIcon/>}
        {collapsed && <ChevronRightIcon/>}
      </ListItemButton>
    </ListItem>

    <NavButton label={"Dashboard"} collapsed={collapsed} action={() => history.push(paths.dashboard)} icon={<DashboardIcon />} />
    <NavButton label={"Clients"} collapsed={collapsed} action={() => history.push(paths.clients)} icon={<PeopleAltIcon />} />
    <NavButton label={"Chats"}
               secondary={<RippleBadge overlap="circular"
                                       invisible={!hasNewMessages}
                                       anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                       variant="dot">
                 <div/>
               </RippleBadge>}
               collapsed={collapsed}
               action={() => history.push(paths.chats)} icon={<ChatIcon />} />
    <NavButton label={"Tags"} collapsed={collapsed} action={() => history.push(paths.tags)} icon={<SellIcon />} />
    <NavButton label={"Files"} collapsed={collapsed} action={() => history.push(paths.files)} icon={<FileCopyIcon />} />
    <NavButton label={`Missing Apps ${missingAppTotal}`} collapsed={collapsed} action={() => history.push(paths.missingApps)} icon={<PermContactCalendarIcon />} />
    <NavButton label={"Urg. Missing Apps"} collapsed={collapsed} action={() => history.push(paths.urgentMissingApps)} icon={<PermContactCalendarIcon color={'error'} />} />
    <NavButton label={"2 Hour SLA"} collapsed={collapsed} action={() => history.push(paths.twoHourSla)} icon={<RememberMeIcon />} />
    <NavButton label={"Attempted Leads"} collapsed={collapsed} action={() => history.push(paths.attemptedLeads)} icon={<ContactPhoneIcon />} />

    {isAgentManager && <NavButton label={"Agents"} collapsed={collapsed} action={() => history.push(paths.agents)} icon={<RecordVoiceOverIcon />} />}
    <NavButton label={"Integrations"} collapsed={collapsed} action={() => history.push(paths.integrations)} icon={<IntegrationInstructionsIcon />} />
    <NavButton label={"Plan coverage"} collapsed={collapsed} action={() => history.push(paths.planCoverage)} icon={<BlindsClosedIcon />} />
    <NavButton label={"Agent data"} collapsed={collapsed} action={() => history.push(paths.agent)} icon={<RecordVoiceOverIcon />} />
    {isAgentManager && <NavButton label={"Email templates"} collapsed={collapsed} action={() => history.push(paths.emailTemplates)} icon={<EmailIcon />} />}

    {!isAdmin && <NavButton label={"Clients"} collapsed={collapsed} action={() => history.push(paths.clients)} icon={<PeopleAltIcon />} />}

  </List>
}

const NavButton = ({action, collapsed, label, icon, secondary}: {collapsed: boolean, label: string, action: () => void, icon: JSX.Element, secondary?: JSX.Element}) => {
  return <ListItem disablePadding>
    <Tooltip title={collapsed ? label : ''}>
      <ListItemButton onClick={action}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        {!collapsed && <ListItemText sx={{pl: 2}} primary={label} />}
        {!!secondary && <ListItemSecondaryAction>
          {secondary}
        </ListItemSecondaryAction>}
      </ListItemButton>
    </Tooltip>
  </ListItem>
}
