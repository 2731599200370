import {DateRangeInput} from "../../enrollment-types";
import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextInput from "../TextInput";
import moment from "moment";

interface FilterProps<T> {
  value?: T | null,
  onChange: (value?: T) => void
}

const DaterangeFilter = ({value, onChange, label}: FilterProps<DateRangeInput> & {label: string}) => {
  const [range, setRange] = useState<DateRangeInput>(value as DateRangeInput);

  useEffect(() => {
    onChange(range)
  }, [range]);

  return <Box display={'flex'} alignItems={'center'} gap={1}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableFuture
        slotProps={{
          textField: {
            inputProps: {
              placeholder: 'MM / DD / YYYY',
              className: 'mb-0'
            },
          }
        }}
        className={'mb-0'}
        slots={{
          textField: TextInput as any,
        }}
        format="MM/dd/yyyy"
        label={label + ' from'}
        value={range?.from ? moment(range!.from).toDate() : null}
        onChange={value => setRange(prev => ({
          from: value ? moment(value).startOf('day').toISOString() : value,
          to: prev?.to || moment().startOf('day').toISOString()
        }))}
      />
    </LocalizationProvider>

    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        slotProps={{
          textField: {
            inputProps: {
              placeholder: 'MM / DD / YYYY',
              className: 'mb-0'
            },
          }
        }}
        className={'mb-0'}
        slots={{
          textField: TextInput as any,
        }}
        format="MM/dd/yyyy"
        label={label + ' to'}
        value={range?.to ? moment(range!.to).toDate() : null}
        onChange={value => setRange(prev => ({
          from: prev?.from || moment(value).startOf('day').toISOString(),
          to: value ? moment(value).startOf('day').toISOString() : value
        }))}
      />
    </LocalizationProvider>
  </Box>
}

export default DaterangeFilter;
