import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import {keycloak, KeycloakRoles} from "../shared/keycloak";
import NewLeadsStatistics from "./NewLeadsStatistics";
import UrgentCallRequests from "./UrgentCallRequests";
import TwoHrCallback from "./TwoHrCallback";

function ResponsiveAppBar() {


  return (
    <AppBar position="static">
      <Toolbar disableGutters sx={{justifyContent: 'space-between'}}>
        <img src={'/img/Logo_white.svg'} className={'mr-24'} width={100} height={19} />

        <Button onClick={() => keycloak.logout()}>Logout</Button>
      </Toolbar>
      <NewLeadsStatistics />
      <TwoHrCallback />
      <UrgentCallRequests />
    </AppBar>
  );
}
export default ResponsiveAppBar;
