import {
  Box,
  Card,
  Divider,
  IconButton, Typography
} from "@mui/material";
import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import moment from "moment/moment";
import {useFormik} from "formik";
import AgentSelector from "../../Agent/AgentSelector";
import {WithCalls} from "../tools";
import {ClientViewOutput, useSaveStatusMutation} from "../../enrollment-types";
import {FollowUpSelector} from "../../features/connect-client-date-selector";

export default function FollowUpDrawerContent({client, onClose}: {client: WithCalls<ClientViewOutput>, onClose: (refresh?: boolean) => void}) {
  const [loading, setLoading] = useState(false);
  const [save] = useSaveStatusMutation();

  const formik = useFormik({
    initialValues: {
      followUpDate: client.followUpDate,
      agentId: client.agent?.id,
    },
    onSubmit: values => {
      if (!values.followUpDate) {
        formik.setFieldError('followUpDate', 'Date is required')
      } else if (!values.agentId) {
        formik.setFieldError('agentId', 'Agent is required')
      } else {
        setLoading(true);
        save({ variables: {
            id: client.id,
            status: client.status,
            agentId: values.agentId,
            followUpDate: moment(values.followUpDate).format('YYYY-MM-DD')
          }
        })
          .finally(() => {
            setLoading(false);
            onClose(true);
          })
      }
    }
  });


  return <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <IconButton size={'small'} onClick={() => onClose()}>
        <img className={'w-32 h-32'} src={'/img/X.svg'}/>
      </IconButton>
    </Box>
    <Typography variant={'h3'}>Schedule next contact</Typography>
    <Divider sx={{mb: 3}} />
    <Box component={'form'} sx={{flexDirection: 'column', gap: 1, display: 'flex'}} onSubmit={formik.handleSubmit}>
      <AgentSelector
        value={formik.values.agentId}
        onChange={(agentId) => {
          formik.setFieldValue("agentId", agentId);
        }}
        error={formik.touched.agentId && Boolean(formik.errors.agentId) ? formik.errors.agentId as string : undefined}/>

      <FollowUpSelector value={moment(formik.values.followUpDate).isValid() ? moment(formik.values.followUpDate).toDate() : null}
                        error={formik.touched.followUpDate ? formik.errors.followUpDate as string : undefined}
                        onChange={value => formik.setFieldValue("followUpDate", value)} />

      <LoadingButton variant={'contained'}
                     loading={loading}
                     type={'submit'}
                     color={'primary'}>Save</LoadingButton>
    </Box>
  </Card>
}
