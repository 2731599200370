import React from "react";
import {useLocation} from "react-router-dom";
import paths from "../router-paths";
import withErrorBoundary from "../shared/withErrorBoundary";
import CssBaseline from '@mui/material/CssBaseline';
import ResponsiveAppBar from "../Layout/ResponsiveAppBar";
import Container from "@mui/material/Container";
import {Box} from "@mui/material";
import Navigation from "../Navigation";
import {Router} from "./Router";

//277821
function Layout() {
  const location = useLocation();

  return (
    <>
      <CssBaseline />
      <ResponsiveAppBar />

      <Box sx={{display: 'flex', gap: 1, flex: 1}}>
        <Navigation />

        <Container maxWidth={location.pathname === paths.dashboard && "xl"} sx={{py: 3, width: location.pathname !== paths.dashboard ? 'calc(100% - 160px)' : 1}}>
          <Router />
        </Container>
      </Box>

    </>
  );
}

export default withErrorBoundary(Layout, true);


